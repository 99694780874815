import RegisterForm from "./registerPanel";
import InvitePanel from "./invitePanel";
import React, {useEffect, useState} from "react";
import {ContentContainer, StyledHeader} from "./style";
import {generateHash, LinkNew} from "./methods";
import {FaWhatsapp} from "react-icons/fa6";
import {GB, NL} from "country-flag-icons/react/3x2";
import {activePanel, emptyPayload, payloadType} from "./interfaces";
import { usePathContext} from "./context";
import {doesCookieExist, getDataFromCookie} from "./cookies";
import {fetchUserData} from "./aws-interactions";
import {useLocation} from "react-router-dom";


function useQuery() {
    let urlQuery = ''
    if (typeof document !== 'undefined') {
        const {search} = useLocation();
        urlQuery = search
    }
    return React.useMemo(() => new URLSearchParams(urlQuery), [urlQuery]);
}


const DefenceContent: React.FC = () => {
    const { isEnglish, setIsEnglish, toggleEnglish, panelState, setPanelState, text, setSubmitted, setRegistrationData} = usePathContext();

    const panelStates: activePanel[] = ['register', 'invite'];

    const query = useQuery().get("h") || generateHash();


      // Use useEffect to run the logic only once, on first load
    useEffect(() => {
        if (typeof document !== "undefined") {
            if (doesCookieExist()) {
                const cookieData = getDataFromCookie();
                if (cookieData !== null) {
                    setRegistrationData(cookieData);
                    setIsEnglish(cookieData.en)
                    setSubmitted(true);
                }
            } else {
                const fetchAndSetUserData = async () => {
                    if (query[0] != '0') {
                        const userData = await fetchUserData(query);

                        if (userData !== undefined) {
                            setRegistrationData({
                                ...emptyPayload,
                                hash: query,
                                name: userData.name,
                                email: userData.email,
                                en: userData.en
                            });
                            setIsEnglish(userData.en)
                        }
                    } else {
                        setRegistrationData({
                            ...emptyPayload,
                            hash: query,
                        })
                    }
                };
                fetchAndSetUserData().then(); // Call the async function
            }
        }
    }, []);


    // Header Component
    const Header = () => (
        <StyledHeader columnCount={panelStates.length}>
            <div onClick={toggleEnglish} className="no-border flag">
                <span>
                    <LinkNew to="https://api.whatsapp.com/send?phone=31631515577" disableIcon={true}>
                        <FaWhatsapp />
                    </LinkNew>
                </span>
                <span>{isEnglish ? <NL /> : <GB />}</span>
            </div>
            <div>
                {panelStates.map((state) => (
                    <div
                        key={state}
                        onClick={() => setPanelState(state)}
                        className={panelState === state ? 'active' : ''}
                    >
                        <h3>{text[state]?.label || state}</h3>
                    </div>
                ))}
            </div>
        </StyledHeader>
    );

    // PanelContent Component
    const PanelContent = () => {
        switch (panelState) {
            case 'register':
                return <RegisterForm/>;
            case 'invite':
                return <InvitePanel/>;
            default:
                return null;
        }
    };

    // Main Component Return
    return (
        <ContentContainer>
            <Header />
            <PanelContent />
        </ContentContainer>
    );
};

export default DefenceContent;

