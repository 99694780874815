import {hashType} from "./interfaces";

const AWS_UNHASH_GATEWAY_URL = 'https://td1eg6e3ck.execute-api.eu-west-1.amazonaws.com/default/unhashDefenceName'

const api_key = process.env.AWS_UNHASH_API_KEY ?? "";


export const fetchUserData = async (hash: string): Promise<undefined | hashType> => {
    try {
        // Fetch user text-data from the API
        const response = await fetch(`${AWS_UNHASH_GATEWAY_URL}?hash=${hash}`, {
            method: 'GET',
            headers: {
                'X-Api-Key': api_key,
            },
        });
        // If the response status is 404 (not found), return undefined
        if (response.status === 404) {
            return undefined;
        }

        // If the response is not OK and not 404, throw an error
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Parse and return the JSON response
        return await response.json();
    } catch (error) {
        console.error("Failed to fetch user text-data:", error);
        return undefined; // Optionally return undefined on other errors
    }
};
