import styled from 'styled-components';

export const BannerImage = styled.div`
  position: absolute;
  height: var(--banner-size, 300px);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  //margin-bottom: 30px;
  //@media (max-width: 990px) {
  //  margin-bottom: 20px;
  //}
  //@media (max-width: 767px) {
  //  margin-bottom: 10px;
  //}

  //}
  div.gatsby-image-wrapper {
    position: fixed;
    width: 100%;
    max-height: calc(var(--banner-size, 300px) + 10px);
    min-height: var(--banner-size, 300px);
  }
    
    //@media (max-width: 1400px) {
    //  max-height: var(--banner-size, 300px);
    //} 
    //@media (max-width: 990px) {
    //  max-height: var(--banner-size, 300px);
    //}  
    //@media (max-width: 575px) {
    //  max-height: var(--banner-size, 300px);
    //}
    //min-height: var(--banner-size, 300px);
    //z-index: -2;
  // #veil {
  //   position: absolute;
  //   overflow: hidden;
  //   top: calc(var(--banner-size, 300px) + 111px);
  //   height: 100vh;
  //   max-height: calc(100vh - var(--banner-size, 300px) - 111px);
  //   @media (max-width: 1400px) {
  //     top: calc(var(--banner-size, 300px) + 101px);
  //     max-height: calc(100% - var(--banner-size, 300px) - 101px);
  //   }  
  //   @media (max-width: 990px) {
  //     top: calc(var(--banner-size, 300px) + 78px);
  //     max-height: calc(100% - var(--banner-size, 300px) - 78px);
  //   }  
  //   @media (max-width: 575px) {
  //     top: calc(var(--banner-size, 300px) + 54px);
  //     max-height: calc(100% - var(--banner-size, 300px) - 54px);
  //   }
  //  
  //   width: 100%;
  //   background-color: red; // ${({ theme }) => theme.body};
  //   z-index: -1;
  // } 
`;