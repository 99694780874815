import React from "react";
import {FaUpRightFromSquare} from "react-icons/fa6";

export const LinkNew = ({ to, disableIcon = false, children }: {
  to: string;
  disableIcon?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <a href={to} target="_blank" rel="noopener noreferrer">
      {children} {!disableIcon && <FaUpRightFromSquare />}
    </a>
  );
};

export function generateHash(length: number = 3): string {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_';
    let result = '0';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
    }
    return result;
}