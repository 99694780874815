import React from "react";
import Layout from '../../../src/components/layout';
import SEO from '../../../src/components/seo';
import {
    BrowserRouter as Router,
} from "react-router-dom";
import {PageContextType} from "./interfaces";
import DefenceStateProvider from "./context";
import DefenceContent from "./components";

const debugMode = false

const DefenceApp = ({ pageContext }: { pageContext: PageContextType })  => {
    const RouterWrapper = typeof document === "undefined" ? React.Fragment : Router;
    return (
        <Layout do_sticky={false} do_footy={false} do_recaptcha={true}>
            <SEO
                title="Maartens Verdediging en Verjaardag"
                description="7 Maart verdedig ik mijn proefschrift en vier mijn 30e verjaardag. Ben jij er bij?"
                image={pageContext.preview}
            />
            <RouterWrapper>
                <DefenceStateProvider debugMode={debugMode} pageContext={pageContext}>
                    <DefenceContent/>
                </DefenceStateProvider>
            </RouterWrapper>
        </Layout>
    );
};


export default DefenceApp;

