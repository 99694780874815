import multiLanguageText from '../text-data/structure'
import TextType from "../text-data/interface";
import {activePanel, emptyPayload, PageContextType, payloadType} from "./interfaces";
import React, {
    Dispatch,
    SetStateAction,
    useState,
    createContext,
    useContext, useEffect,
} from "react";
import {DefenceStateProviderProps} from "./interfaces";


const PathContext = createContext<{
    isEnglish: boolean;
    setIsEnglish: Dispatch<SetStateAction<boolean>>;
    panelState: activePanel;
    toggleEnglish: () => void;
    setPanelState: Dispatch<SetStateAction<activePanel>>;
    text: TextType;
    debugMode: boolean;
    pageContext: PageContextType;
    submitted: boolean;
    setSubmitted: Dispatch<SetStateAction<boolean>>;
    registrationData: payloadType,
    setRegistrationData: Dispatch<SetStateAction<payloadType>>;
    headlineState: string;
    setHeadlineState: Dispatch<SetStateAction<string>>;
}>({
    isEnglish: false,
    setIsEnglish: () => {},
    panelState: 'invite',
    toggleEnglish: () => {},
    setPanelState: () => {}, // This line is valid because it technically accepts any input
    text: {} as TextType, // Using a type assertion for initialization
    debugMode: false,
    pageContext: {
        preview: '',
        defence: undefined,
        party: undefined,
    },
    submitted: false,
    setSubmitted: () => {},
    registrationData: emptyPayload,
    setRegistrationData: () => {},
    headlineState: '',
    setHeadlineState: () => {},
});



export const usePathContext = () => {
    const context = useContext(PathContext);
    if (!context) {
        throw new Error("usePathContext must be used within a PathProvider");
    }
    return context;
};


export const DefenceStateProvider: React.FC<DefenceStateProviderProps> = ({ children, debugMode, pageContext }) => {
    const [isEnglish, setIsEnglish] = useState<boolean>(false);
    const [panelState, setPanelState] = useState<activePanel>('register');
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [registrationData, setRegistrationData] = useState<payloadType>(emptyPayload);

    // Function to toggle the language between English and Dutch
    const toggleEnglish = () => {
        setIsEnglish(prevState => !prevState);
    };

    // Select the text based on the current language state
    const text: TextType = isEnglish ? multiLanguageText.en : multiLanguageText.nl;
    const [headlineState, setHeadlineState] = useState<string>(text.registerContext);

    useEffect(() => {
        if (submitted) {
            setHeadlineState(text.confirm);
        } else {
            setHeadlineState(text.registerContext);
        }

    }, [isEnglish]);

    return (
        <PathContext.Provider
            value={{
                isEnglish,
                setIsEnglish,
                panelState,
                toggleEnglish,
                setPanelState,
                text,
                debugMode,
                pageContext,
                submitted,
                setSubmitted,
                registrationData,
                setRegistrationData,
                headlineState,
                setHeadlineState
            }}
        >
            {children}
        </PathContext.Provider>
    );
};

export default DefenceStateProvider;



