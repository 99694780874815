import styled from 'styled-components';
import {darkTheme} from '../../../src/components/reset-css'

export const ContentContainer = styled.div`
    width: 100%;
    height: 100vh;
    max-width: 600px;
    margin: auto;
    
    padding: 5px;
    @media (min-width: 600px) {
        padding: 1rem;
    }
    table {
        width: fit-content;
        margin: auto;
    }
    
    a, .link-like, a:visited, .link-link:visited {
        color: ${({ theme }) => theme === darkTheme ? '#708bff' : '#2251ff'};
        cursor: pointer;
    }
    
    /* Hover link color */
    a:hover, .link-like:hover {
        color: ${({ theme }) => theme === darkTheme ? '#9fb0f8' : '#173bc2'};
    }
    
    /* Active link color */
    a:active, .link-like:active {
        color: ${({ theme }) => theme === darkTheme ? '#c9d5ff' : '#112c8c'};
    }
    
    /* For webkit-based browsers (like Chrome and Safari) */
    a:-webkit-any-link a {
        color: ${({ theme }) => theme === darkTheme ? 'red' : 'inherit'};
    }
`

export const StyledPanelContainer = styled.div`
    padding: 1rem 0 2rem 0;
    .blur {
        filter: blur(8px);
    }
    .debug {
        left: 0;
        top: 0;
        position: absolute;
        width: 600px;
        overflow: hidden;
    }
`

export const StyledPopup = styled.div`
    @media (min-width: 575px) {
        padding: 1rem;
    }
    @media (min-width: 600px) {
        max-width: calc(600px - 2rem);
    }
    
    box-shadow: 3px 3px 10px ${({theme}) => theme.shadow};
    
    img, div.gatsby-image-wrapper{
        height: 150px;
    }
`

export const StyledHeader = styled.div<{ columnCount: number }>`
    div.flag {
        font-size: 1.5em;
        width: 100%;
        height: 1.5rem;
        display: flex;
        justify-content: space-between;
        svg {
            cursor: pointer;
            border-radius: 5px;
            height: 1em;
            margin: 0;
        }
    }
    div:not(.flag){
        margin: 5px 0 5px 0;
        display: grid;
        gap: 5px;
        grid-template-columns: repeat(${(p) => p.columnCount}, 1fr);
        div {
            box-shadow: 3px 3px 10px ${({theme}) => theme.shadow};;
            display: flex;
            justify-content: center;
            border: 1px solid ${({theme}) => theme.semibackground};
        }
        div.active {
            background-color: ${({theme}) => theme.semiforeground};
            color: ${({theme}) => theme.body};
            border: none;
        }
        h1, h2, h3, h4, h5 {
            margin: 10px;
        }
    }
    // height: fit-content;
    // display: grid;
    // //grid-template-columns: 5% 1fr 1fr 1fr 5%;
    // grid-template-columns: 0 1fr 1fr 1fr 10px;
    // //gap: 1rem;
    // padding: 2rem 0;
    //
    // div {
    //     display: flex;
    //     justify-content: center;
    //     border-radius: 5px;
    //     &:not(.no-border) {
    //         border: 1px solid ${({theme}) => theme.semibackground};
    //        
    //         cursor: pointer;
    //         box-shadow: 3px 3px 10px ${({theme}) => theme.shadow};;
    //     }
    //     &.active {
    //         /* background-color: ${({theme}) => theme === darkTheme ? 'red' : 'blue'}; */
    //         background-color: ${({theme}) => theme.semibackground};
    //     }
    //
    // }
    // div.flag {
    //     background-color: red;
    //     border-radius: 5px;
    //     height: fit-content;
    //     overflow: hidden;
    //     cursor: pointer;
    // }
    //
    // h1, h2, h3, h4, h5 {
    //     margin: 10px
    // }
`

// export const Red = styled.span`
//     color: red;
// `

export const Asterisk = styled.span<{ error: boolean }>`
  color: ${(p) => (p.error ? 'red' : 'transparent')};
  &::after {
      content: '*';
  }
`;

export const StyledRegistrationForm = styled.form`
    td {
        border: 0;
        padding: 5px;
    }

    tr {
        height: 3.4rem;
    }
    table {
        width: 100%; /* Make the table fill the available width */
        table-layout: fixed; /* Ensure the table respects width rules */
    }
    
    td:first-child {
        white-space: nowrap; /* Prevent wrapping in the first column */
    }
    
    td:last-child {
        width: 100%; /* Let the last column take the remaining space */
    }

    input, select {
        color: ${({theme}) => theme.text};
        border-radius: 5px;
        border: 1px solid ${({theme}) => theme.text};
        width: 100%;
        font-size: 1rem;
        padding: 0.5rem;
        outline: none;
        background-color: transparent;
        &:disabled {
            color: ${({theme}) => theme.semiforeground};
            border: 1px solid ${({theme}) => theme.semibackground};
        }
    }
    button {
        svg {
            margin: 0 0 -2px 0;
        }
        margin: 1em auto;
    background-color: ${({theme}) => theme.semiforeground};
    border: 2px solid ${({theme}) => theme.text};;
    color: ${({theme}) => theme.body};;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 3px 3px 10px ${({theme}) => theme.shadow};;
}
    
    div.button-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }

button:hover {
    @media (hover: hover) {
        background-color: ${({theme}) => theme.semibackground};
        color: ${({theme}) => theme.text};
    }
}

button:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(52, 152, 219, 0.6);
}
    width: 100%;
`