import {ReactNode, FunctionComponent, useRef} from 'react';
import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SocialProfile from '../../components/social-profile/social-profile';
import ContactForm from '../../components/form/contact-form';
import {
  FaOrcid,
  FaGithub,
  FaLinkedin,
  FaStrava,
  FaInstagram,
  FaXTwitter,
} from 'react-icons/fa6';

import {
  BsFileEarmarkPerson
} from 'react-icons/bs'

import {
  StandaloneWrapper,
  StandaloneDetails,
  SocialProfiles,
} from './style';
import Footer from "../../components/footer/footer";

interface StandaloneProps {
  greeting?: ReactNode;
  body?: string[];
  socials?: string[];
  do_contact?: boolean;
  greet?: boolean,
}

const StandaloneContent: FunctionComponent<StandaloneProps> = ({
  greeting = null,
  body = [],
  socials = [],
  do_contact = false,
  greet = false,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          socialUrls { 
            linkedin
            github
            orcid
            strava
            twitter
            instagram
            resume
          }
        }
      }
    }
  `);
  const { author, socialUrls } = Data.site.siteMetadata;
  const SocialLinks = [
  {icon: <BsFileEarmarkPerson />,  url: socialUrls.resume,     tooltip: 'Resume',},
  {icon: <FaLinkedin />,           url: socialUrls.linkedin,   tooltip: 'LinkedIn',},
  {icon: <FaGithub />,             url: socialUrls.github,     tooltip: 'GitHub',},
  {icon: <FaStrava />,             url: socialUrls.strava,     tooltip: 'Strava',},
  {icon: <FaOrcid />,              url: socialUrls.orcid,      tooltip: 'Orcid',},
  {icon: <FaInstagram />,          url: socialUrls.instagram,  tooltip: 'Instagram',},
  {icon: <FaXTwitter />,           url: socialUrls.twitter,    tooltip: 'X',},
  ];

  // Filter SocialLinks based on whether their lowercase tooltip is present in socials
  const filteredSocialLinks = SocialLinks.filter(link =>
    socials.includes(link.tooltip.toLowerCase())
  );
  const titleReference = useRef<HTMLHeadingElement>(null)

  return (
    <StandaloneWrapper className={"transition-join"}>
      <div className={"stand-alone-wrapper-styling"}>
      <StandaloneDetails>
        <h1 ref={titleReference}>{greeting},</h1>
        {body && <>{body.map((paragraph, index) => (
          <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
        ))}</>}
        {do_contact && <ContactForm title={titleReference}/>}
        {greet && author &&<><p>Best,</p><p>{author}</p></>}
        <SocialProfiles>
          <SocialProfile items={filteredSocialLinks} />
        </SocialProfiles>
      </StandaloneDetails>
        </div>
      <Footer/>
    </StandaloneWrapper>
  );
};

export default StandaloneContent;

