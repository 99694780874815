import TextType from "./interface";
import {FaCircleInfo, FaWhatsapp} from "react-icons/fa6";
import {LinkNew} from "../src/methods";
import React, {Dispatch, SetStateAction} from "react";
import {activePanel} from "../src/interfaces";

const Invite = ({setDefenceState, setPartyState, setPanelState}: {
        setDefenceState: Dispatch<SetStateAction<boolean>>,
        setPartyState: Dispatch<SetStateAction<boolean>>,
        setPanelState: Dispatch<SetStateAction<activePanel>>,
    }) => {
    return (
        <div>
            <p>Beste vrienden, familie en collega's,</p>

            <p>Met veel plezier nodigen wij u uit voor de promotie van Maarten Poirot en de feestelijke vieringen in de avond.</p>

            <p>
                <strong>Verdediging <FaCircleInfo className='link-like'
                                              onClick={() => setDefenceState(true)}/></strong><br/>
                <strong>Datum en tijd:</strong> Vrijdag 7 maart, om 9:45 uur<br/>
                <strong>Locatie:</strong> <LinkNew
                to={'https://maps.app.goo.gl/pXdnegzsEmNZJqbE8'}>Agnietenkapel</LinkNew>
            </p>

            <p>De verdediging van het proefschrift met de titel <em>"Artificial Intelligence and MRI for the Prediction of
                Treatment Outcome in Depression"</em> begint precies om 10:00 uur, en de deuren van de Agnietenkapel worden tijdens de ceremonie gesloten. Daarom vragen wij u vriendelijk om minimaal 15 minuten van tevoren aanwezig te zijn voor een soepel verloop.</p>

            <p>De Agnietenkapel is het beste te bereiken per metro of tram naar het Rokin. Beperkte parkeergelegendheid is ook beschikbaar in de parkeergarage onder het Rokin.</p>

            <p>Om Maartens prestatie en zijn aankomende 30e verjaardag te vieren, nodigen wij u van harte uit voor de festiviteiten later die dag:</p>

            <p>
                <strong>Feest <FaCircleInfo className='link-like'
                                            onClick={() => setPartyState(true)}/></strong><br/>
                <strong>Tijd:</strong> 20:00 uur<br/>
                <strong>Locatie:</strong> <LinkNew
                to={'https://maps.app.goo.gl/aGshZeBYvgdnB42P7'}>Rijnbar</LinkNew>
            </p>

            <p>
                Het feest vindt plaats in de Rijnbar. De Rijnbar is
                <LinkNew to={'https://maps.app.goo.gl/eFPYKDf1TPcvGaua9'}> op 17 minuten loopafstand</LinkNew> van station Amstel of een 13 minuten durende rit met tram 4 vanaf de RAI P+R. Voor degenen die met de auto komen, is er parkeergelegenheid bij RAI P+R voor €6 per 24 uur met een OV-chipkaart. Om in aanmerking te komen voor de P+R-korting, lees de instructies op de <LinkNew to={'https://www.rai.nl/bereikbaarheid/p-en-r'}>RAI P+R-website</LinkNew>.
            </p>

            <p>Laat ons weten of u aanwezig kunt zijn via het <span
                className='link-like' onClick={() => setPanelState('register')}>Registratiepaneel</span>. We hopen u daar te zien om deze mijlpalen samen te vieren!</p>
            <p>Met vriendelijke groet,</p>
            <p><strong>Jonas Riksen</strong> & <strong>Laura van Poppel</strong><br/>Paranimfen</p>
        </div>
    )
}

const defenceText = (
    "<p>Een promotie is de academische ceremonie waarin je als kandidaat zijn je proefschrift verdedigt en de titel" +
    " <em>doctor</em> behaalt. Het markeert de culminatie van jarenlang wetenschappelijk onderzoek en draagt bij aan" +
    " de vooruitgang van kennis binnen een specifiek vakgebied.\n" +
    "De ceremonie vindt plaats in de Agnietenkapel, monumentaal pand uit 1470 van de Universiteit van Amsterdam. " +
    "De verdediging begint om 10 uur, dan gaat de deuren dicht en is het niet meer toegestaan om binnen te komen. Zorg" +
    "er daarom voor dat je een kwartier van tevoren aanwezig bent om het te vinden, je jas op te hangen en plaats te " +
    "nemen.</p>" +
    "<p>De verdediging begint met een presentatie van mij over wat ik onderzocht heb tijdens mijn onderzoek en waarin ik de" +
    " belangrijkste resutaten presenteer. Dit duurt stipt vijftien minuten. Hierna komt de commissie van hoogleraren, de" +
    " zogenaamde <em>opponenten</em> pas binnen. Dit alles gebeurt in een formele en plechtige setting. Zo is een " +
    "<em>pedel</em> die je hierbij instrueert te gaan staan en zitten. Ook wordt de promovendus in de voorbereiding en " +
    "tijdens de verdediging bijgestaan door twee <em>paranimfen</em>, vaak goede collega's of vrienden. De opponenten " +
    "stellen één voor één kritische " +
    "vragen tot de tijd op is. Dan gaat de commissie de ruimte weer uit ten beraadslaging. Na enige tijd komen zij terug" +
    " de ruimte in om het oordeel te vellen over de verdediging. Bij een succesvolle verdediging wordt de doctorstitel" +
    "dan officieel toegekend. Na de verdediging volgt een kleine receptie om het te vieren. Het geheel duurt circa 2 " +
    "uur.</p>" +
    "<p>Voordat deze plechtigheid plaatsvindt is het proefschrift al beoordeeld door de goedkeuringscommissie. Daarmee is " +
    "de verdediging voornamelijk een ceremoniële plechtigheid. Alleen in uitzonderlijke gevallen wordt de doctorstitel " +
    "niet toegekend na afronding van de verdediging.</p>"
)

const partyText = (
    "<p>In de avond van 7 maart nodig ik je graag uit om het behalen van de doctorstitel, en mijn aankomende verjaardag " +
    "op 20 maart samen te vieren.</p>" +
    "<p>Vanaf acht tot laat vieren we het in de Rijnbar aan de Rijnstraat, schuin tegenover ons huis aan de Van Woustraat. " +
    "Bier, fris en wijn is afgekocht tot een bedrag dus komt vroeg.</p>" +
    "<p>Beperkte slaapplaatsen zijn beschikbaar, stuur me hiervoor een <a href=\"https://api.whatsapp.com/send?phone=31631515577\" target=\"_blank\" rel=\"noopener noreferrer\">WhatsAppje</a>."
)

const lang = {
    "common": "<strong>7 maart</strong> verdedig ik mijn proefschrift om <strong>10 uur</strong>. Om <strong>20 uur</strong> vieren we dit en mijn 30e verjaardag in Amsterdam.",
    "register": {
        "label": "Aanmelden"
    },
    "date": "Vrijdag 7 maart 2025",
    "registerContext": "Ben jij er bij?",
    "confirm": "Je hebt de volgende informatie doorgegeven:",
    "submitConfirmation": "Versturen",
    "redo": "Bewerken",
    "none": "Geen",
    "required": "verplicht",
    "name": {
        "label": "Naam",
        "placeholder": "Voor- en achternaam"
    },
    "email": {
        "label": "E-mail",
        "placeholder": "email@voorbeeld.com"
    },
    "defence": {
        "label": "Woon je de verdediging bij?",
        "physical": "Ja, in persoon",
        "virtual": "Ja, digitaal",
        "none": "Nee",
        "confirm": "Bijwonen verdediging",
        "info": {
            "header": "Verdediging",
            "text": defenceText,
        },
    },
    "party": {
        "label": "Ben je bij het feest?",
        "physical": "Ja",
        "none": "Nee",
        "confirm": "Aanwezigheid feest",
        "info": {
            "header": "Feest",
            "text": partyText,
        },
    },
    "thesis": {
        "label": "Wilt u het proefschrift ontvangen?",
        "virtual": "Ja, digitaal",
        "physical": "Ja, op papier",
        "none": "Nee",
        "confirm": "Proefschrift"
    },
    "partner": {
        "label": "Neem je iemand mee?",
        "physical": "Ja, een +1",
        "none": "Nee",
        "confirm": "Neem je iemand mee"
    },
    "submitButton": "Versturen",
    "submittingButton": "Versturen...",
    "registerHeader": "Aanmelden",
    "invite": {
        "label": "Uitnodiging",
        "component": Invite
    },
    "close": "Klik ergens om te sluiten"
} as TextType

export default lang;
