import React, {useEffect, useState, useRef} from 'react';
import GatsbyImage from '../../components/gatsby-image';
import SVGImage from "../svg-image/svg-image";
// import PostAudio from "../audio-div/audio-div";
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import {isNew, setItem} from "../use-cookie";
import {incrementView} from "../../interact";
import BlogMeta from "../blog-meta/blog-meta";
import {CounterFallbacks} from "../../interact";

import {
    PostWrapper,
    PostTitle,
    // PostDate,
    PostHeaderImage,
    PostHeaderContent,
    PostText, HorizontalSeparator,
    // PostTags,
    // HorizontalSeparator,
    // StyledSVG,
    // StyledSVGWrapper,
} from './post-details.style';

type PostDetailsProps = {
    slug: string;
    title: string;
    date?: string;
    preview: any | null;
    color?: string;
    description: any;
    outline: any;
    tags?: string[];
    className?: string;
    audioURL: string | null;
    audio?: number | null;
    n_fallbacks: CounterFallbacks;
};



const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        // Check if window is defined (client-side)
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', handleResize);

            // Initial call to set the window width
            setWindowWidth(window.innerWidth);

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, []);

    return windowWidth;
};


const PostDetails: React.FunctionComponent<PostDetailsProps> = ({
                                                                    slug,
                                                                    title,
                                                                    date,
                                                                    preview,
                                                                    color,
                                                                    description,
                                                                    outline,
                                                                    tags,
                                                                    className,
                                                                    audioURL,
                                                                    audio,
                                                                    n_fallbacks,
                                                                    ...props
                                                                }) => {
    // Load the reCAPTCHA executioner
    const {executeRecaptcha} = useGoogleReCaptcha();

    // Slugs come like /slug/ which are inconvenient to handle so remove /
    const safe_slug = slug.replace(/\//g, '')

    // Was_new_visit is used to add a number to the page's view count, because
    // the CloudFront text-data has to be invalidated during the visit of the page.
    // Thus, it is static.
    const wasNewVisit = useRef(isNew(safe_slug))

    // Is_new_visit is used to track if the page count update has to be sent
    // during the visit of the page. After the request has been sent it updates,
    // thus, it is dynamic.
    const [isNewVisit, setIsNewVisit] = useState<boolean>(wasNewVisit.current);

    const BlogMetaInstance = () => {
        return (
            <BlogMeta
                max_n_tags={3}
                slug={slug}
                tags={tags}
                date={date}
                can_interact={true}
                className={'post-meta'}
                isVisit={true}
                isNewVisit={wasNewVisit.current}
                n_fallbacks={n_fallbacks}
                audioURL={audioURL}
                audio={audio}
            />
        )
    }

    const PostOutline = () => {
        return (
            <PostText
                dangerouslySetInnerHTML={{__html: outline}}
                className="post-out"
            />
        )
    }

    const PostPreviewInstance = () => {
        return (
            <PostHeaderImage className="post-preview">
                {typeof preview === 'string' ? (
                    <SVGImage
                        src={preview}
                        color={color}
                        alt={title}
                    />
                ) : (
                    <GatsbyImage src={preview} alt={title}/>
                )}
            </PostHeaderImage>
        )
    }

    const BodyInstance = () => {
        return (
            <PostText
                dangerouslySetInnerHTML={{__html: description}}
                className="post-des"
            />
        )
    }
    const metaFC = useRef(<BlogMetaInstance/>)
    const postOutlineFC = useRef(<PostOutline/>)
    const previewFC = useRef(<PostPreviewInstance/>)
    const bodyFC = useRef(<BodyInstance/>)

    useEffect(() => {
        // Nothing to do if visitor is not new or executeRecaptcha is not available
        if (!isNewVisit || !executeRecaptcha) {
            return;
        }
        const timeoutId = setTimeout(async () => {
            await onSubmit();
            // console.log('Welcome new visitor!')
            setItem(safe_slug)
            setIsNewVisit(true); // Mark submission as successful
        }, 200);

        // Cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(timeoutId);
    }, [isNewVisit, executeRecaptcha]); // Re-run effect when isNewVisit or executeRecaptcha changes

    const onSubmit = async () => {
        if (!executeRecaptcha) {
            return false
        }
        const token = await executeRecaptcha('contact')
        await incrementView(slug, token)
        return true
    };

    if (preview === null) {
        preview = ''
    } else if (preview.publicURL.endsWith('.svg')) {
        preview = preview.publicURL;
    } else {
        preview = preview.childImageSharp.gatsbyImageData;
    }

    const windowWidth = useWindowWidth();


    return (
        <PostWrapper {...props} className={"transition-join"}>
            <div className='preview-container'>
                {previewFC.current}
            </div>
            <PostHeaderContent className="post-des-wrapper-high">
                <div>
                    <PostTitle className="post-title">{title}</PostTitle>
                    {windowWidth >= 780 && postOutlineFC.current}
                </div>
                <div>
                    {windowWidth > 500 && (metaFC.current)}
                </div>
            </PostHeaderContent>
            <HorizontalSeparator/>
            <PostHeaderContent className="post-des-wrapper-low">
                {windowWidth < 780 && postOutlineFC.current}
                {windowWidth <= 500 && (metaFC.current)}
                {bodyFC.current}
            </PostHeaderContent>
        </PostWrapper>
    );
};

export default PostDetails;
