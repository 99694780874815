import TextType from "./interface";
import en from "./en";
import nl from "./nl";

export const multiLanguageText: {
  en: TextType;
  nl: TextType;
} = {
  "en": en,
  "nl": nl
};

export default multiLanguageText;