import React, {Dispatch, SetStateAction} from "react";
import {StyledPopup, StyledPanelContainer} from "./style";
import {usePathContext} from "./context";
import GatsbyImage from "../../../src/components/gatsby-image";
import {activePopup} from "./interfaces";

const InfoPopup = ({setPopupState, popupType}: { setPopupState: Dispatch<SetStateAction<boolean>>, popupType: activePopup }) => {
    const {text, pageContext} = usePathContext();
    return (
        <StyledPopup onClick={() => setPopupState(false)}>
            <GatsbyImage src={pageContext[popupType]} alt="Header image"/>
            <h1>{text[popupType].info.header}</h1>
            <div dangerouslySetInnerHTML={{__html: text[popupType].info.text}}/>
            <p><em>{text.close}</em></p>
        </StyledPopup>
    )
};

const InvitePanel = () => {
    const {text, setPanelState} = usePathContext();
    const [defenceState, setDefenceState] = React.useState<boolean>(false);
    const [partyState, setPartyState] = React.useState<boolean>(false);

    const InviteComponent = text.invite.component

    return (
        <StyledPanelContainer>
            {defenceState ? (
                <InfoPopup popupType='defence' setPopupState={setDefenceState}/>
            ) : (partyState ? (
                <InfoPopup popupType='party' setPopupState={setPartyState}/>
            ) : <InviteComponent setDefenceState={setDefenceState} setPartyState={setPartyState} setPanelState={setPanelState}/>)}
        </StyledPanelContainer>
    );
};


export default InvitePanel;