import React from 'react'
import {StyledExcuse} from "./utmb-gui.style";
import {RaceMetadata} from "./interfaces";
import {float2hhmm, float2perc, mostCommonString, percParticipants, titleCleaner, zip, calcMean} from "./functions";
import {categoryToBarColor, categoryToColor, countryCodeToFlag} from "./utmb-gui-utils";
import {YearCheckBox} from  "./components"
import UtmbIcon from "./icons";
import {
    FaLink
} from 'react-icons/fa6';
import {Bar} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const UtmbInfoPanel: React.FC<{
    yearFilter: boolean[],
    setYearFilter: any,
    raceUid: string,
    histData: number[][],
    metaData: RaceMetadata,
    countryCode?: string,
    anchorRef?: any,
}> = (
    {
        yearFilter,
        setYearFilter,
        raceUid,
        histData,
        metaData,
        countryCode = 'NL',
        anchorRef = null,
    }) => {

    // Extract the bin starting positions (first column) and histogram text-data (other columns)
    const binLabels = histData.length > 0 ? histData[0].map(x => float2hhmm(x)) : [];

    const cleanedTitles: string[] = metaData['Race Title']?.map(
        (title, index) => (titleCleaner(title, metaData['Year'][index]))
    )

    // Get the most common string from the array, if tied get the last
    let bestTitle = mostCommonString(cleanedTitles);

    const ToggleYear = (index: number) => {
        const newFilter = {...yearFilter}
        newFilter[index] = !newFilter[index]
        setYearFilter(newFilter)
    }

    const barColors = categoryToBarColor[metaData['Race Category'].at(-1)]

    const datasets = histData.length > 0 && metaData
        ? zip([histData.slice(1), metaData['Year']]).map(([heights, year], index) => {
            if (!yearFilter[index]) {
                heights = [];
            }
            return {
                label: year ? year.toString() : '',
                data: heights,
                backgroundColor: barColors[index],
                borderColor: 'gray',
            };
        })
        : [];

    const chartData = {
        labels: binLabels,
        datasets: datasets,
    };

    const chartOptions = {
        scales: {
            x: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Finish time (hh:mm)',
                },
                stacked: true, // Stack bars on the x-axis
            },
            y: {
                beginAtZero: true,
                stacked: true, // Stack bars on the y-`1axis
            },
        },
    };

    const fMeta: { [key: string]: any[] } = {};

    Object.keys(metaData).forEach(key => {
        fMeta[key] = metaData[key].filter((_, index) => yearFilter[index]);
    });

    let meanElev = '-'
    let meanDist = '-'
    let meanTime = '-'
    let meanDnf = '-'
    if (fMeta['Year'].length > 0) {
        if (fMeta['N DNF'].length > 0) {
            const dnfr = fMeta['N DNF'].map((value, index) => {
                return value / fMeta['N Participants'][index];
            });
            meanDnf = float2perc(calcMean(dnfr));
        } else if (fMeta.hasOwnProperty('DNF R') && ['DNF R'].length > 0) {
            meanDnf = `${Math.round(fMeta['DNF R'][0]).toString()}%`
        }

        meanElev = Math.round(calcMean(fMeta['Elevation Gain'])).toString() + ' m';
        meanDist = Math.round(calcMean(fMeta['Distance'])).toString() + ' km';
        meanTime = float2hhmm(calcMean(fMeta['Mean Finish Time']));
    }
    const raceCat = metaData['Race Category'].at(-1)
    return (
        <div>
            <h1 id='summary-title' ref={anchorRef}>{bestTitle}</h1>
            <div className="utmb-overview">
                <div>
                    <p className='overview-head dist'>Distance</p>
                    <p className='overview-dist'>{meanDist}</p>
                </div>
                <div>
                    <p className='overview-head elevation'>Elevation</p>
                    <p className='overview-elevation'>{meanElev}</p>
                    <p className='overview-elev'>{Math.round(parseFloat(meanElev) / 100) / 10}</p>
                </div>
                <div>
                    <p className='overview-head cat'>Category</p>
                    <p className='overview-cat'><UtmbIcon category={raceCat} style={{height: 22}}/></p>
                </div>
                <div>
                    <p className='overview-head time'>Time</p>
                    <p className='overview-time'>{meanTime}</p>
                </div>
                <div>
                    <p className='overview-head dnf'>DNF</p>
                    <p className='overview-dnf'>{meanDnf}</p>
                </div>
                <div>
                    <p className='overview-head country'>Country</p>
                    <p className='overview-country'>{countryCodeToFlag(countryCode)}</p>
                </div>
            </div>
            <table className='utmb-race'>
                <thead>
                <tr
                    style={{backgroundColor: categoryToColor[raceCat]}}
                    className={`table-heading ${raceCat === '100M' || raceCat === '-' ? 'light-font' : 'dark-font'}`}
                >
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    {/*<th>Category</th>*/}
                    {/*<th>Countries</th>*/}
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>
                {metaData['Race Title'].map((_, index) => (
                    <tr key={index}>
                        <td><YearCheckBox index={index} yearFilter={yearFilter} ToggleYear={ToggleYear}/></td>
                        <td>{metaData['Year'][index].toString().slice(2)}</td>
                        <td>{Math.round(metaData['Distance'][index] + 1)}</td>
                        <td>{metaData['Elevation Gain'][index]}</td>
                        {/*<td>{metaData['Race Category'][index]}</td>*/}
                        <td>{percParticipants(metaData, 'N DNF', index)}</td>
                        <td>{float2hhmm(metaData['Winning Time'][index])}</td>
                        <td>{float2hhmm(metaData['Mean Finish Time'][index])}</td>
                        <td>{float2hhmm(metaData['Last Time'][index])}</td>
                        {/*<td>{metaData['N Countries'][index]}</td>*/}
                        <td>{metaData['N Participants'][index] > 0 ? metaData['N Participants'][index] : '-'}</td>
                        <td>{percParticipants(metaData, 'N Women', index)}</td>

                        <td><a
                            href={`https://utmb.world/utmb-index/races/${raceUid}..${metaData['Year'][index]}`}
                            target="_blank" rel="noopener noreferrer">
                            <FaLink style={{color: (raceCat != '-' ? categoryToColor[raceCat] : '#0AD2F3')}}/>
                        </a>
                        </td>
                    </tr>
                ))}
            </table>

            {histData.length > 0 ? (
                <>
                    <h2>Finish times</h2>
                    <Bar data={chartData} options={chartOptions}/>
                </>
            ) : (
                <StyledExcuse>Nobody finished this race.</StyledExcuse>
            )}
        </div>
    );
};

export default UtmbInfoPanel