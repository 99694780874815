import TextType from "./interface";
import { FaCircleInfo, FaWhatsapp } from "react-icons/fa6";
import { LinkNew } from "../src/methods";
import React, { Dispatch, SetStateAction } from "react";
import { activePanel } from "../src/interfaces";

const Invite = ({ setDefenceState, setPartyState, setPanelState }: {
        setDefenceState: Dispatch<SetStateAction<boolean>>,
        setPartyState: Dispatch<SetStateAction<boolean>>,
        setPanelState: Dispatch<SetStateAction<activePanel>>,
    }) => {
    return (
        <div>
            <p>Dear friends, family, and colleagues,</p>

            <p>We are delighted to invite you to Maarten Poirot's PhD defense and the evening celebrations.</p>

            <p>
                <strong>Defense <FaCircleInfo className='link-like'
                                              onClick={() => setDefenceState(true)} /></strong><br />
                <strong>Date and Time:</strong> Friday, March 7th, at 9:45 AM<br />
                <strong>Location:</strong> <LinkNew
                to={'https://maps.app.goo.gl/pXdnegzsEmNZJqbE8'}>Agnieten Chapel</LinkNew>
            </p>

            <p>The defense of the dissertation titled <em>"Artificial Intelligence and MRI for the Prediction of Treatment Outcome in Depression"</em> starts promptly at 10:00 AM. The doors of the Agnieten Chapel will be closed during the ceremony, so we kindly ask you to arrive at least 15 minutes early to ensure a smooth start.</p>

            <p>The Agnieten Chapel is best reached by metro or tram to Rokin. Limited parking is available in the parking garage under Rokin.</p>

            <p>To celebrate Maarten's achievement and his upcoming 30th birthday, we warmly invite you to the festivities later that day:</p>

            <p>
                <strong>Party <FaCircleInfo className='link-like'
                                            onClick={() => setPartyState(true)} /></strong><br />
                <strong>Time:</strong> 8:00 PM<br />
                <strong>Location:</strong> <LinkNew
                to={'https://maps.app.goo.gl/aGshZeBYvgdnB42P7'}>Rijnbar</LinkNew>
            </p>

            <p>
                The party will take place at Rijnbar, which is
                <LinkNew to={'https://maps.app.goo.gl/eFPYKDf1TPcvGaua9'}> a 17-minute walk</LinkNew> from Amstel station or a 13-minute tram ride on line 4 from the RAI P+R. For those traveling by car, parking is available at RAI P+R for €6 per 24 hours with an OV-chipkaart. To qualify for the P+R discount, please follow the instructions on the <LinkNew to={'https://www.rai.nl/bereikbaarheid/p-en-r'}>RAI P+R website</LinkNew>.
            </p>

            <p>Let us know if you can attend via the <span
                className='link-like' onClick={() => setPanelState('register')}>Registration Panel</span>. We hope to see you there to celebrate these milestones together!</p>
            <p>Kind regards,</p>
            <p><strong>Jonas Riksen</strong> & <strong>Laura van Poppel</strong><br />Paranymphs</p>
        </div>
    )
}

const defenceText = (
    "<p>A PhD defense is the academic ceremony where a candidate defends their dissertation and earns the title of <em>doctor</em>. It marks the culmination of years of scientific research and contributes to advancing knowledge in a specific field.\n" +
    "The ceremony takes place in the Agnieten Chapel, a historic building from 1470 belonging to the University of Amsterdam. " +
    "The defense begins at 10:00 AM, and the doors will be closed at that time, prohibiting further entry. Therefore, please arrive at least 15 minutes early to locate the venue, hang up your coat, and take your seat.</p>" +
    "<p>The defense starts with a presentation by me, explaining my research and presenting the main results. This takes exactly 15 minutes. Afterward, the committee of professors, called <em>opponents</em>, enters. This all happens in a formal and ceremonial setting. There is also a <em>beadle</em> who instructs you on when to stand and sit. The opponents take turns asking critical questions until time runs out. The committee then leaves the room to deliberate. After some time, they return to deliver their judgment on the defense. Upon a successful defense, the doctoral title is officially awarded. A small reception follows to celebrate. The entire event lasts about 2 hours.</p>" +
    "<p>Before this ceremony takes place, the dissertation has already been evaluated by the approval committee. Thus, the defense is mainly a ceremonial event. Only in exceptional cases is the doctoral title not awarded after the defense.</p>"
)

const partyText = (
    "<p>In the evening of March 7th, I would love to invite you to celebrate earning my doctorate and my upcoming birthday on March 20th together.</p>" +
    "<p>From 8 PM until late, we will celebrate at Rijnbar on Rijnstraat, diagonally across from our house on Van Woustraat. Beer, soft drinks, and wine will be covered up to a certain amount, so come early.</p>" +
    "<p>Limited sleeping arrangements are available; send me a <a href=\"https://api.whatsapp.com/send?phone=31631515577\" target=\"_blank\" rel=\"noopener noreferrer\">WhatsApp</a> message to inquire."
)

const lang = {
    "common": "<strong>March 7th</strong> I will defend my dissertation at <strong>10 AM</strong>. At <strong>8 PM</strong>, we will celebrate this and my 30th birthday in Amsterdam.",
    "register": {
        "label": "Register"
    },
    "date": "Friday, March 7, 2025",
    "registerContext": "Will you be attending?",
    "confirm": "You have provided the following information:",
    "submitConfirmation": "Submit",
    "redo": "Edit",
    "none": "None",
    "required": "required",
    "name": {
        "label": "Name",
        "placeholder": "First and last name"
    },
    "email": {
        "label": "Email",
        "placeholder": "email@example.com"
    },
    "defence": {
        "label": "Will you attend the defense?",
        "physical": "Yes, in person",
        "virtual": "Yes, online",
        "none": "No",
        "confirm": "Defense Attendance",
        "info": {
            "header": "Defense",
            "text": defenceText,
        },
    },
    "party": {
        "label": "Will you attend the party?",
        "physical": "Yes",
        "none": "No",
        "confirm": "Party Attendance",
        "info": {
            "header": "Party",
            "text": partyText,
        },
    },
    "thesis": {
        "label": "Would you like to receive the dissertation?",
        "virtual": "Yes, digital",
        "physical": "Yes, paper",
        "none": "No",
        "confirm": "Dissertation"
    },
    "partner": {
        "label": "Will you bring a guest?",
        "physical": "Yes, a +1",
        "none": "No",
        "confirm": "Bringing a guest"
    },
    "submitButton": "Submit",
    "submittingButton": "Submitting...",
    "registerHeader": "Sign Up",
    "invite": {
        "label": "Invitation",
        "component": Invite
    },
    "close": "Click anywhere to close"
} as TextType

export default lang;
