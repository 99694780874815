import React from "react";
import {IGatsbyImageData} from "gatsby-plugin-image";
import { Dispatch, SetStateAction } from 'react';
import {generateHash} from "./methods";

export type attendanceType = 'physical' | 'virtual' | 'none' | '';

export interface hashType {
    name: string;
    email: string;
    en: boolean;
}

export type activePanel = 'register' | 'invite'

export type activePopup = 'defence' | 'party'

export interface InviteProps {
  setDefenceState: Dispatch<SetStateAction<boolean>>;
  setPartyState: Dispatch<SetStateAction<boolean>>;
  setPanelState: Dispatch<SetStateAction<activePanel>>;
}


export interface payloadType extends hashType {
    hash: string;
    defence: attendanceType;
    party: attendanceType;
    thesis: attendanceType;
    partner: attendanceType;
    recaptcha: string;
}

export const emptyPayload = {
    hash: generateHash(),
    name: '',
    email: '',
    en: false,
    defence: 'none',
    party: 'none',
    thesis: 'none',
    partner: 'none',
    recaptcha: '',
} as payloadType;


export interface PageContextType {
    preview: string;
    defence?: IGatsbyImageData;
    party?: IGatsbyImageData;
}


export interface DefenceStateProviderProps {
    debugMode: boolean;
    children: React.ReactNode;
    pageContext: PageContextType;
}

