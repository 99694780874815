import * as React from 'react';
import GatsbyImage from '../gatsby-image';
import {BannerImage,} from './style';

const StandaloneBanner = (data: any) => {
    const {src} = data;
    return (
        <BannerImage className={'banner-image'}>
            {src && (
                <GatsbyImage src={src.childImageSharp.gatsbyImageData} alt="banner image"/>
            )}
            {/*<div id={"veil"} />*/}
        </BannerImage>
    );
};

export default StandaloneBanner;
