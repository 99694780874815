import {StyledForm, ErrorMessage, Placeholder} from "./contact-form.style";
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import React, {useState, RefObject} from 'react';
import {useForm} from 'react-hook-form';
import {FaArrowRotateRight} from 'react-icons/fa6';
const AWS_GATEWAY_URL = 'https://uv87ctnza8.execute-api.eu-west-1.amazonaws.com/v1/maartenpoirotContactForm20x';

const ContactForm: React.FC<{ title: RefObject<HTMLHeadingElement> }> = ({title}) => {
    const [mailContent, storeMailContent] = useState({ name: '', email: '', message: '' });
    const [submitted, setSubmitted] = useState(false);
    const {executeRecaptcha} = useGoogleReCaptcha()
    const {
        register,
        handleSubmit,
        reset,
        formState: {errors, isSubmitting}
    } = useForm();

    const api_key = process.env.AWS_CONTACT_FORM_API_KEY ?? "";

    const handleReset = () => {
        setSubmitted(false)
        if (title && title.current) {
            title.current.innerHTML = "Your message,";
        }
    };

    const onSubmit = async (data: any) => {
        storeMailContent(data)

        if (!executeRecaptcha) {
            return
        }

        let msg = "Thank you,"

        try {
            const result = await executeRecaptcha('contact')
            data.recaptcha = result;

            const response = await fetch(AWS_GATEWAY_URL, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                body: JSON.stringify(data),
                headers: {
                    'X-Api-Key': api_key,
                    'Content-type': 'application/json; charset=UTF-8',
                },
            });

            const responseCode = response.status
            if (responseCode == 200) {
                setSubmitted(true);
                reset();
            } else {
                msg = `${responseCode} error`
            }
        } catch (error) {
            console.log(error)
            msg = `Can't send message`
        }
        if (title && title.current) {
            title.current.innerHTML = msg;
        }
        window.scrollTo({top: 100, behavior: "smooth"})
    };

    const showSubmitError = (msg: {} | string | undefined) => <p className="msg-error">{msg}</p>;

    const showThankYou = (
        <div className="msg-confirm">
            <p>Thank you for your message.<br/>
                I will get back to you shortly.</p>
            <button type="button" onClick={handleReset} className={"no-button-content"}>
                <FaArrowRotateRight/>
            </button>
        </div>
    );

    return (<StyledForm onSubmit={handleSubmit(onSubmit)} method="post">
            {errors && errors.submit && showSubmitError(errors.submit.message)}{submitted ? showThankYou :
            <>
            <span className={"left"}>
                <label htmlFor="name">
                    <h3>Name</h3>
                    <input {...register("name", {
                        required: "Your name is required",
                        maxLength: {value: 200, message: "Your name cannot exceed 200 characters"},
                    })}
                           type="text"
                           name="name"
                           id="name"
                           placeholder={mailContent ? mailContent.name : "Enter your name"}
                           disabled={isSubmitting}
                    />
                    {errors.name ?
                        <ErrorMessage>{errors.name.message}</ErrorMessage> : Placeholder}
                </label>
                <label htmlFor="email">
                    <h3>E-mail</h3>
                    <input {...register("email", {
                        required: "Your e-mail is required",
                        maxLength: {value: 320, message: "e-mail addresses cannot exceed 320 characters"},
                    })}
                           type="email"
                           name="email"
                           id="email"
                           placeholder={mailContent ? mailContent.email : "your@email.address"}
                           disabled={isSubmitting}
                    />
                    {errors.email ?
                        <ErrorMessage>{errors.email.message}</ErrorMessage> : Placeholder}
                </label>
                </span>
                <span className={"right"}>
                <label htmlFor="message">
                    <h3>Message</h3>
                    <textarea {...register("message", {
                        required: "A message is required",
                        minLength: {value: 10, message: "Add a bit more..."},
                        maxLength: {value: 1000, message: "Messages cannot exceed 1000 characters"},
                    })}
                              name="message"
                              id="message"
                              rows={10}
                              placeholder={mailContent ? mailContent.message : "Dear Maarten, "}
                              disabled={isSubmitting}
                    />
                    {errors.message ?
                        <ErrorMessage>{errors.message.message}</ErrorMessage> : Placeholder}
                </label>
                <button className={isSubmitting ? 'submitting' : ""} disabled={isSubmitting} type="submit">
                </button>
            </span>
            </>
        }</StyledForm>
    );
};

export default ContactForm