import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StandaloneWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 400px;
  .stand-alone-wrapper-styling{
  padding: 80px 45px 0 45px;
  background-color: ${({ theme }) => theme.body};
  @media (max-width: 560px) {
    padding: 60px 40px 0px 40px;
  }
  @media (max-width: 480px) {
    padding: 40px 15px 0 15px;
  }
    }
`;

export const StandaloneDetails = styled.div`
  width: 870px;
  max-width: 100%;
  margin: 0 auto;
  cursor: default;
  h2 {
    font-size: 21px;
    font-weight: 500;
    color: ${({ theme }) => theme.semiforeground};
    margin-bottom: 40px;
    @media (max-width: 990px) {
      margin-bottom: 30px;
    }
    @media (max-width: 767px) {
      font-size: 18px;
      margin-bottom: 25px;
    }
  }
  p > a:visited {
    color: ${themeGet('colors.primary', '#2251ff')};
  } 
`;

export const SocialProfiles = styled.div`
  margin-top: 60px;
  position: relative;
  @media (max-width: 767px) {
    margin-top: 40px;
  }

  &:before {
    content: '';
    width: 30px;
    height: 1px;
    background-color: ${({ theme }) => theme.semiforeground};
    //transition: background-color .3s ease-in;
    display: block;
    margin-bottom: 60px;
    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }
`;
