import React, {useState, useRef, useEffect, useMemo} from 'react';
// import { useSearchParams } from "react-router-dom";
import BlogPostItem from "../../../components/blog-item/blog-item";
import {StyledItemContainer, StyledItemList} from "../../../components/blog-item/blog-item.style";
import {FilterBar} from "../style";
import Tag from "../../../components/tag/tag";
// import { useLocation } from 'react-router-dom';
// import {Link} from 'gatsby';
// import _ from 'lodash';
// import {incrementClap} from "../../../interact";
// import {setItem} from "../../../components.tsx/use-cookie";
import {
    useLocation
} from "react-router-dom";


interface PostsProps {
    data: {
        allMarkdownRemark: {
            totalCount: number;
            edges: Array<{
                node: {
                    fields: {
                        slug: string;
                    };
                    frontmatter: {
                        // date?: string;
                        title: string;
                        description: string;
                        tags: string[];
                        audio: number | null;
                        claps: number | null;
                        views: number | null;
                        cover: {
                            publicURL: string;
                            childImageSharp: {
                                gatsbyImageData: any;
                            };
                        } | null;
                    };
                    audioURL: string | null;
                };
            }>;
        };
    };
}


type Direction = 'left' | 'none' | 'right'

function useQuery() {
    let urlQuery = ''
    if (typeof document !== 'undefined') {
        const {search} = useLocation();
        urlQuery = search
    }
    return React.useMemo(() => new URLSearchParams(urlQuery), [urlQuery]);
}

const getIconSize = (): string => {
    let isMobile = false
    if (typeof window !== 'undefined') {
        isMobile = window.innerWidth <= 512
    }
    return isMobile ? '80%' : '50%'
}

const Posts: React.FunctionComponent<PostsProps> = ({data}) => {
    let filters = ['published', 'side project', 'sports', 'travel', 'webdev', 'AWS'];
    let query = useQuery().get("tag");
    const tagParam = query && query.replace('-', ' ');
    let tagIndex = tagParam ? filters.indexOf(tagParam) : -1;

    if (tagParam != null && !filters.includes(tagParam)) {
        filters.unshift(tagParam);
        filters.pop();
        tagIndex = 0;
    }

    const curFilterIndex = useRef<number | undefined>(tagIndex);
    const nextFilterIndex = useRef<number | undefined>(undefined);
    const [isMoving, setIsMoving] = useState<boolean>(false);
    const direction = useRef<Direction>('none')
    const fly_in_pos = useRef<Direction>('none')
    const iconSize = useRef<string>(getIconSize())

    const handleFilter = (newIndex: number) => {
        iconSize.current = getIconSize()

        if (newIndex === curFilterIndex.current) {
            newIndex = -1;
            direction.current = 'right';
        } else if (curFilterIndex.current === undefined) {
            direction.current = 'left';
        } else {
            direction.current = newIndex < curFilterIndex.current ? 'right' : 'left';
        }
        fly_in_pos.current = direction.current === 'left' ? 'right' : 'left';
        nextFilterIndex.current = newIndex;
        setIsMoving(true);
    };

    useEffect(() => {
        if (!isMoving) {
            return;
        }
        const animTimeout = setTimeout(() => {
            curFilterIndex.current = nextFilterIndex.current === curFilterIndex.current ? undefined : nextFilterIndex.current;
            nextFilterIndex.current = undefined;
            direction.current = 'none';
            fly_in_pos.current = 'none';
            setIsMoving(false);
        }, 500);
        return () => {
            clearTimeout(animTimeout);
        };
    }, [isMoving]);

    const makePostList = useMemo(() => (filterIndex: number | undefined, direction: Direction, position: Direction) => {
        // filterIndex = (filterIndex === curFilterIndex) && isMoving ? undefined : filterIndex
        const filter = filterIndex === undefined ? undefined : filters[filterIndex]

        return (
            <StyledItemList className={`${position} move-${direction}`}>
                {data.allMarkdownRemark.edges
                    .filter(({node}: any) => !filter || node.frontmatter.tags.includes(filter))
                    .map(({node}: any) => (
                        <div key={node.fields.slug}>
                            <BlogPostItem
                                key={`${node.fields.slug}`}
                                node={node}
                                initialLoad={false}
                                n_fallbacks={{views: node.frontmatter.views ?? 0, claps: node.frontmatter.claps ?? 0}}
                                iconSize={iconSize.current}
                            />
                        </div>
                    ))}

            </StyledItemList>
        );
    }, [data, filters]);

    const FilterBarInstance = () => {
        return (
            <>
                <FilterBar>
                    {filters.map((filter, index) => (
                        <Tag
                            key={filter}
                            isLink={false}
                            text={filter}
                            method={!isMoving ? () => handleFilter(index) : null}
                            className={index === nextFilterIndex.current || index === curFilterIndex.current && nextFilterIndex.current === undefined ? 'active' : ''}
                        />
                    ))}
                </FilterBar>
                {/*<HoverBar*/}
                {/*    from={curFilterIndex.current !== undefined ? curFilterIndex.current : -2}*/}
                {/*    to={nextFilterIndex.current !== undefined ? nextFilterIndex.current :*/}
                {/*        curFilterIndex.current !== undefined ? curFilterIndex.current : -2}*/}
                {/*>*/}
                {/*    <div className='hover-bar'></div>*/}
                {/*</HoverBar>*/}
            </>
        )
    }
    const BlogItemContainerInstance = () => {
        return (
            <StyledItemContainer>
                {nextFilterIndex.current !== undefined ? makePostList(nextFilterIndex.current, direction.current, fly_in_pos.current) : null}
                {makePostList(curFilterIndex.current, direction.current, 'none')}
            </StyledItemContainer>
        )
    }

    return (<><FilterBarInstance/><BlogItemContainerInstance/></>
    );
};

export default Posts;






