import {payloadType} from "./interfaces";

const cookieName = 'registrationCache'

function encryptData(data: payloadType): string {
  return btoa(JSON.stringify(data)); // Convert the text-data to a string and encode it in Base64
}


// Simple decryption (Base64 decoding)
function decryptData(encryptedData: string): payloadType {
  return JSON.parse(atob(encryptedData)); // Decode the Base64 and parse the JSON string
}

export function doesCookieExist(): boolean {
    const cookies = document.cookie.split("; "); // Split all cookies into an array
    return cookies.some(cookie => cookie.startsWith(cookieName));
}

export function storeDataAsCookie(data: payloadType): void {
  const encryptedData = encryptData(data);
  const expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + 365);  // Cookie expires in 1 year
  document.cookie = `${cookieName}=${encryptedData}; expires=${expiryDate.toUTCString()}; path=/`;
}

// Function to retrieve text-data from a cookie
export function getDataFromCookie(): payloadType | null {
  const cookies = document.cookie.split('; ');
  const cookie = cookies.find(c => c.startsWith(cookieName));
  if (cookie) {
    const cookieValue = cookie.split('=')[1];
    try {
        // Decrypt the cookie content first
        const decryptedData = decryptData(cookieValue);

        // Check if the cookie contains both paths and NHintsUsed
        if (decryptedData) {
            return decryptedData;
        }
    } catch (error) {
        console.error('Failed to decrypt or parse cookie text-data:', error);
    }
  }
  return null; // The cookie does not exist
}